import {Player} from '@lottiefiles/react-lottie-player';
import {GetStaticProps} from 'next';
import React from 'react';
import Layout from '@/components/layout';
import {ILocale} from '@/data/locales';
import {useLocaleState} from '@/store/locale';

type IProps = {
    locale: ILocale
}

const Error: React.FC<IProps> = ({locale}) => {
    const [{locale: locales}, {setLocale}] = useLocaleState();

    React.useEffect(() => {
        if (locales !== locale) {
            setLocale({locale});
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [locale]);

    return (
        <Layout>
            <p className="paragraph">
                {locale.page[404]}
            </p>
            <div className="error-animation">
                <Player
                    autoplay
                    loop
                    src='/error.json'
                />
            </div>
        </Layout>
    );
};

export const getStaticProps: GetStaticProps = async context => {
    let locale;

    try {
        locale = (await import(`@/data/locales/${context.locale}`)).default;
    } catch (error) {
        return {notFound: true};
    }

    return ({props: {locale}});
};

export default Error;
